<template>
  <button
    class="button is-size-7"
    :class="[styles, { 'is-loading': waitKey ? $wait.is(waitKey) : false }]"
    :disabled="disabled"
    @click="onClick"
    :title="tooltip"
    v-tippy
  >
    {{ value }}
  </button>
</template>

<script>
export default {
  name: 'DataTableBlockButton',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    },
    styles: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    onClick: {
      type: Function,
      required: false,
      default: null
    },
    waitKey: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    async clickEvent() {
      try {
        if (waitKey) this.$wait.start(waitKey)
        await this.content.meta.layout().clickEvent()
      } finally {
        if (waitKey) this.$wait.end(waitKey)
      }
    }
  }
}
</script>
